<template>  
  <b-row v-if="user && user.gallery">
    <b-col md="6" xl="4" v-for="(img) in user.gallery" :key="img.id">
      <b-card
        :img-src="imgUrl + img.urlmomentpicture"          
        overlay text-variant="white" img-alt="card img"
        body-class="bg-overlay"
        class="gallery-image" 
      >                                       
      </b-card>
    </b-col>            
  </b-row> 
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'

export default {
  data() {
    return {            
      localImages: [],      
      imgs: [],
      imgUrl: process.env.VUE_APP_IMG_SRC_API,                      
      isSavingPhotos: false,
    }
  },
  computed: {
    ...mapState('auth',['user', 'myContracts']),     
  },
  methods: {    
    ...mapActions('auth', ['savePhotosOwner','savePhotosGallery', 'deletePhotoOwner','getPhotosGalleryUser']),
    ...mapMutations('auth', ['setGalleryPhotosUser']),   

    uploadPhotos (event) {
        const title = this.$t('Limit 5 photos')
        const msgLimit = this.$t('Only limit 5 photos')

        const files = event.target.files              
        if( !files || this.localImages.length > 4 ){            
            if(this.localImages.length > 4){
			    showAlertMessage(title, 'XIcon', msgLimit, 'danger', 4000, 'bottom-right')             
            }
            return
        }        
        this.imgs = files
        this.imgs.forEach( (f, index) => {                                
            const fr = new FileReader()                                  
            fr.onload = (e) => {
                if( index < 5){
                    this.localImages.push(  e.target.result  )
                }
            }
            fr.readAsDataURL(f)
        })  
        if( this.imgs.length > 5){                            
			showAlertMessage(title, 'XIcon', msgLimit, 'danger', 4000, 'bottom-right')             
        }           
    },
    newPhotoProfile(img){      
      return img ? `background-image: url('${img}');` : ''           
    },
    imgGallery(img){              
      return img ? `background-image: url('${this.imgUrl + img.urlmomentpicture }');` : ''           
    },
    removeImgPreview(img){        
      this.localImages.splice(img, 1)
    },
    clearPhotosPreview(){
      this.localImages = []
    },
    async savePhotos(){
        this.isSavingPhotos = true        
        const payloadPhotos = []
        this.imgs.forEach( photo => {
            payloadPhotos.push({idOwner: this.user.idOwner, photo })
        })            
        const responses = await this.savePhotosGallery(payloadPhotos)        
        const  { status } = responses[responses.length - 1][0] //obtengo la ultima respuesta      
        if( status ){
            const title = this.$t(`Photos saved`)
            const mgs = this.$t(`The photos have been saved`)
			      showAlertMessage(title, 'XIcon', mgs, 'success', 4000, 'bottom-right')             
        }        
        this.isSavingPhotos = false        
        this.localImages = []
        this.imgs = []  
    },
    async deletePhoto(img){
        const isConfirmedDelete = await this.confirmDelete()
        if(isConfirmedDelete){
            const payload = { idOwner: this.user.idOwner, idGallery: img.id }
            const { status } = await this.deletePhotoOwner(payload) 
            if( status ){
                const title = this.$t(`Photo deleted`)
                const mgs = this.$t(`Your photo has been removed`)
                showAlertMessage(title, 'XIcon', mgs, 'success', 4000, 'bottom-right')   
                const data = { idOwner: this.user.idOwner }
                const { urlmomentpicture } = await this.getPhotosGalleryUser(data)   
                this.setGalleryPhotosUser(urlmomentpicture)          
            }                    
        }
    },
    async confirmDelete() {
      const { isConfirmed } = await this.$swal.fire({
        title: `${ this.$t('Are you sure') }`, 
        text: `${ this.$t('Are you sure to delete the photo') }`,
        showDenyButton: true,
        confirmButtonText: `${ this.$t('Yes, sure') }`,
        denyButtonText: `${ this.$t('Cancel') }`,
        customClass: {                
            confirmButton: 'mr-1',        
        }
      })
      return isConfirmed
    }
  }
}
</script> 

<style scoped>

p {
  margin: 0;
}
.upload__box {
	padding:0 20px 0 20px;
}
.upload__inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
 .upload__btn {
	 display: inline-block;
	 font-weight: 600;
	 color: #fff;
	 text-align: center;
	 min-width: 116px;
	 padding: 5px;
	 transition: all 0.3s ease;
	 cursor: pointer;
	 border: 2px solid;
	 background-color: #4045ba;
	 border-color: #4045ba;
	 border-radius: 10px;
	 line-height: 26px;
	 font-size: 14px;
}
 .upload__btn:hover {
	 background-color: unset;
	 color: #4045ba;
	 transition: all 0.3s ease;
}
 .upload__btn-box {
	 margin-bottom: 10px;
}
 .upload__img-wrap {
	 display: flex;
	 flex-wrap: wrap;
	 margin: 0 -10px;
}
 .upload__img-box {
	 width: 200px;
	 padding: 0 10px;
	 margin-bottom: 12px;
}
 .upload__img-close {
	 width: 24px;
	 height: 24px;
	 border-radius: 50%;
	 background-color: rgba(179, 11, 11, 0.5);
	 position: absolute;
	 top: 10px;
	 right: 10px;
	 text-align: center;
	 line-height: 24px;
	 z-index: 1;
	 cursor: pointer;
}
 .upload__img-close:after {
	 content: '\2716';
	 font-size: 14px;
	 color: white;
}
 .img-bg {
	 background-repeat: no-repeat;
	 background-position: center;
	 background-size: cover;
	 position: relative;
	 padding-bottom: 100%;
}
 
.gallery-image:hover {
	transform: scale(1.10);
}



</style>