<template>
  <b-card>
    <b-card-body>     
      <div class="card-container">
        <span class="membership" v-if="myContracts"> {{myContracts.contractSelected.membershipcode}}</span>         
          <div class="lang">
            <b-navbar-nav class="nav align-items-center" v-if="languages.length">
              <Language/>                        
            </b-navbar-nav>
          </div>
          <div v-if="user">
            <img class=" circle-img" :src="imgUrl + user.urlphoto" alt="user" v-if="user.urlphoto"/>
            <img class=" circle-img" :src="require('@/assets/images/default-profile.png')" alt="user" v-else/>

            <h3>{{ user.prefix ? user.prefix: '' }} {{ user.name +' '+ user.lastname }}</h3>
            <h6> <feather-icon icon="MailIcon"/> {{ user.email }}</h6> 
            <div class="d-flex justify-content-between">              
              <div>
                <feather-icon icon="PhoneIcon"/><b > {{ $t('Phone') }}:  {{ user.phone }}</b>          
              </div>
              <div>
                <feather-icon icon="FlagIcon"/> <b > {{ $t('Language') }}: {{ user.languageName }}</b>       
              </div>
            </div>              
          </div>
      </div>   
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import Language from '@/modules/auth/components/Language'

export default {
   components: { 
    Language,
  },
  computed:{      
    ...mapState('auth',['user', 'myContracts']),   
    ...mapState('owners', ['languages']),

  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,     
      oldPassword: '',                
    }
  },
}
</script>
<style scoped>
.card-container {
	position: relative;
	width: 100%;	
	text-align: center;
}

.card-container .membership {
	color: #231E39;
	background-color: #FEBB0B;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	padding: 3px 7px;
	position: absolute;
	top: 0px;
	left: 0px;
}

.card-container .lang {
  color: #231E39;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 600;	
	position: absolute;
	top: 0px;
	right: 0px;  
}

.card-container .circle-img {
	border: 1px solid #03BFCB;
	padding: 3px;
  width: 160px;
  height: 160px;
  border-radius: 100%;

  margin-block-end: 1rem;
}

</style>