<template>
	<b-card>
  		<b-card-header><h4> {{ $t('Change password') }}  </h4></b-card-header>
        <b-card-body>
          	<validation-observer ref="formChangePassword" tag="form" v-slot="{ invalid }">	
				<b-form @submit.prevent="handlerChangePassword">
					<b-row>                                        
					<b-col md="6"> 							
						<b-form-group>
						<div class="d-flex justify-content-between">
							<label for="old-password"> {{ $t('Old Password') }}</label>
						</div>
						<validation-provider #default="{ errors }" :name="$t('Old Password')" rules="required" >
							<b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
							<b-form-input
								id="old-password"
								v-model="oldPassword"
								:state="errors.length > 0 ? false:null"
								class="form-control-merge"
								:type="passwordFieldTypeOld"                      
								placeholder="*********"
							/>
							<b-input-group-append is-text>
								<feather-icon class="cursor-pointer" :icon="passwordToggleIconOld" @click="togglePasswordOld" />
							</b-input-group-append>
							</b-input-group>
						<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
						</b-form-group>
					</b-col>            
					</b-row>

					<b-row>        
					<b-col md="6">            
						<b-form-group>
						<div class="d-flex justify-content-between">
						<label for="new-password"> {{ $t('New Password') }}</label>
						</div>
						<validation-provider #default="{ errors }" name="new password" rules="required|min:6" vid="password">
							<b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
							<b-form-input
								id="new-password"
								v-model="newPassword"
								:state="errors.length > 0 ? false:null"
								class="form-control-merge"
								:type="passwordFieldTypeNew"                      
								placeholder="*********"
							/>
							<b-input-group-append is-text>
								<feather-icon class="cursor-pointer" :icon="passwordToggleIconNew" @click="togglePasswordNew"
							/>
							</b-input-group-append>
							</b-input-group>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
						</b-form-group>
					</b-col>        
					<b-col md="6">
						<b-form-group>
						<div class="d-flex justify-content-between">
							<label for="confirm-password">{{ $t('Confirm new Password') }}</label>
						</div>
						<validation-provider #default="{ errors }" :name="$t('Confirm new Password')" rules="required|confirmed:password" >
							<b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
								<b-form-input
								id="confirm-password"
								v-model="confirmPassword"
								:state="errors.length > 0 ? false:null"
								class="form-control-merge"
								:type="passwordFieldTypeRetype"                      
								placeholder="*********"                         
							/>
							<b-input-group-append is-text>
								<feather-icon class="cursor-pointer" :icon="passwordToggleIconRetype" @click="togglePasswordRetype"/>
							</b-input-group-append>
							</b-input-group>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
						</b-form-group>
					</b-col>               
					</b-row>
					<div class="d-flex justify-content-between">              
						<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'"  variant="warning" class="mt-1 mr-1" @click="resetForm">  {{ $t('Reset') }}</b-button>
						<b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 " :disabled="invalid || isChangingPassword ">                
							<span v-if="isChangingPassword"> <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" /> {{ $t('Changing password in process...') }}</span>
							<span v-if="!isChangingPassword"> <font-awesome-icon icon="fa-solid fa-save" /> {{ $t('Save changes') }}  </span>
						</b-button>
					</div>
				
				</b-form>
        	</validation-observer>
        </b-card-body>
    </b-card>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage } from '@/helpers/helpers'
import { utils } from "@/modules/owners/mixins/utils"

export default {
	mixins: [ utils ],
	directives: {
		Ripple,
	},
	computed:{      
		...mapState('auth',['user']), 
		passwordToggleIconOld() {
			return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		passwordToggleIconNew() {
			return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		passwordToggleIconRetype() {
			return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},  
	},
	data() {
		return {         
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
			passwordFieldTypeOld: 'password',
			passwordFieldTypeNew: 'password',
			passwordFieldTypeRetype: 'password',
			isChangingPassword: false,                 
		}
	},
	methods: {
		...mapActions('auth',['changePassword']),
		...mapActions('owners', ['pushActionsService']),	
		togglePasswordOld() {
			this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
		},
		togglePasswordNew() {
			this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
		},
		togglePasswordRetype() {
			this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
		},
		async handlerChangePassword(){
			// validar si es admin desde mixin en utils
			if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}

			this.isChangingPassword = true			
			const payload = { idOwner:this.user.idOwner, oldPassword: this.oldPassword, newPassword: this.newPassword }      
			const { status, message } =  await this.changePassword( payload )
			// const status = true
			// const message = 'Su contraseña ha sido cambiada'
			if(status){
				Object.assign(this.$data, this.$options.data())//limpio data o reset
				this.$refs.formChangePassword.reset();
				showAlertMessage( 'Cambio de contraseña', 'InfoIcon', `${message} : Inicie sesión con su nueva contraseña`, 'success', 4000, 'bottom-right')     
			} else {
				showAlertMessage( 'Cambio de contraseña falló', 'XIcon', message, 'danger', 4000, 'bottom-right')             
			}
			await this.pushData('onClick', 'changePassword', 'changePassword', this.$route.name, ) // push a back del registro				
			this.isChangingPassword = false
		},
		async resetForm(){
			Object.assign(this.$data, this.$options.data())
			this.$refs.formChangePassword.reset();
			await this.pushData('onClick', 'resetForm', 'resetForm', this.$route.name, 'change password' ) // push a back del registro				
		},		
	}
}
</script>