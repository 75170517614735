<template>
  <div class="container-profile">         
    <div class="avatar-upload">
        <div class="avatar-edit">
            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" @change="onSelectedImage" ref="imgSelector"/>
            <label for="imageUpload" ><font-awesome-icon icon="fa-solid fa-edit" class="icon-edit"/></label>
        </div>          
        <div class="avatar-preview" v-if="user">
            <div :style="photoProfile" v-if="!localImage"></div>
            <div :style="newPhotoProfile" v-if="localImage"></div>  
            <div class="text-center mt-1" v-if="localImage">
              <b-button variant="primary" size="sm" @click="updateProfilePhoto" :disabled="isSavingPhoto">        
                <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" v-if="isSavingPhoto" /> 
                <font-awesome-icon icon="fa-solid fa-save" class="icon-edit-avatar" v-if="!isSavingPhoto"/>
              </b-button>
            </div>          
        </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'   
import { utils } from "@/modules/owners/mixins/utils"
import { toJson } from "@/helpers/helpers";

export default {
	mixins: [ utils ],

	data() {
		return {      
			localImage: null,
			file: null,
			imgUrl: process.env.VUE_APP_IMG_SRC_API,                      
			isSavingPhoto: false,
		}	
  	},
	computed: {
		...mapState('auth',['user', 'myContracts']), 
		...mapState('owners', ['languages']),
    
		photoProfile(){  
			const urlPhoto = this.user.urlphoto ?
							`background-image: url('${this.imgUrl}${this.user.urlphoto }');` : 
							`background-image: url('${require('@/assets/images/default-profile.png')}');`                          
			return urlPhoto 
		},
		newPhotoProfile(){      
			return this.localImage ? `background-image: url('${this.localImage}');` : ''           
		}
	},
  	methods: {    
		...mapActions('auth', ['updatePhotoOwner']),
		...mapActions('owners', ['pushActionsService']),	
		...mapMutations('auth', ['setUser']),
		async onSelectedImage(event){  	
      

			this.isSavingPhoto = false
			const file = event.target.files[0]
			if( !file ){
				this.localImage = null
				this.file = null
				return
			}
			this.file = file
			const fr = new FileReader()
			fr.onload = () => this.localImage = fr.result
			fr.readAsDataURL( file )  
			await this.pushData('onChange', 'changePhotoProfile', 'changePhotoProfile', this.$route.name) // push a back del registro				
		},
		
		async updateProfilePhoto(){ 
      // validar si es admin desde mixin en utils
			if( this.isAdmin ){
				return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
			}		
      		  
			this.isSavingPhoto = true
			const payload = {                        
				idOwner: this.user.idOwner,
				idUser: this.user.idOwner,                
				status: true,
				photo: this.file
			}        
			const userUpdated = await this.updatePhotoOwner(payload)
	
      const userData = toJson(userUpdated)
      const userConected = toJson(this.user)


      userData.languageCode = userConected.languageCode || ''
      userData.languageName = userConected.languageName || ''
      userData.languageId = userConected.languageId
      delete userData.languagecode
      delete userData.languagename

			this.setUser(userData) // muto los datos del user ya actualizado

      const idLangs = {
        1: 'en',
        2: 'es',
        3: 'pt',
      }
      
      this.$i18n.locale = idLangs[userData.languageId]

			this.localImage = null
			this.file = null   
			await this.pushData('onChange', 'updatePhotoProfile', 'updatePhotoProfile', this.$route.name) // push a back del registro				

		},		
  	}
}
</script> 

<style scoped>

.container-profile {
  max-width: 960px;
  margin: 0 auto;  
}
h1 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 20px;
}
h1 small {
  display: block;
  font-size: 15px;
  padding-top: 8px;
  color: gray;
}
.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  top: 10px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #30b830;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.icon-edit{
  color: #ece7e7;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
	border: 1px solid #03BFCB;  
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

</style>