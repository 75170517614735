<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }} </span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { toJson } from '@/helpers/helpers'
import { overWriteVeeValidateLocalize } from "@/helpers/authHelper"
export default {

  data(){
    return {
      locales:[
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          name: 'English',
        },
        {
          locale: 'es',
          img: require('@/assets/images/flags/es.jpg'),
          name: 'Spanish',
        },      
      ]
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
		...mapState('owners', ['languages']),
		...mapState('auth',['user']),
		...mapGetters('auth',['contractSelected']),

		currentLocale() {
			return this.locales.find(l => l.locale === this.$i18n.locale)
		},
  },
 
  methods:{
		...mapActions('owners', ['getHotelPerformance']),
		...mapMutations('owners',['setHotelPerformance']),  
    	...mapMutations('auth', ['setLanguageUser'] ),
		async setLocale(locale){  
			const langs = {
				en: 1,
				es: 2,
				pt: 3,
			}
			
			const dataLang = toJson( this.languages.find( lang => lang.id == langs[locale] ) )
			this.$i18n.locale = locale 
			const langUser = {
				languageCode: dataLang.code,
				languageId: dataLang.id,
				languageName: dataLang.name,
			}

			this.setLanguageUser(langUser)
			overWriteVeeValidateLocalize(dataLang.code)
			// overWriteFlatPickrLocalize(dataLang.code)

			const idLanguage = this.user.languageId
			const idResort = this.contractSelected.resortsid

			const hotelP = await this.getHotelPerformance({language: idLanguage, resort: idResort, isOwner: true })
			this.setHotelPerformance(hotelP)
		}

  }
}
</script>

<style>

</style>
