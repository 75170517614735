<template>
<div style="padding: 1rem;">    
  <b-row>
    <b-col md="6">
      <CardProfile/>
    </b-col>
    <b-col md="6">
      <CardChangePassword/>
    </b-col>
  </b-row>
 
  <SkeletonPhotosGallery v-if="isLoadingData" />              
  <b-card v-else>
    <b-card-body>
      <b-tabs active @click="clickTabAccount($t('Photo profile'))">
        <b-tab>
            <template #title>
              <font-awesome-icon icon="fa-solid fa-user" style="color:purple"/>
              <span>{{ $t('Photo profile')}}</span>
            </template>
          <ChangePhotoProfile/>
        </b-tab>
        <b-tab @click="clickTabAccount($t('Upload photo gallery'))">
          <template #title>
            <font-awesome-icon icon="fa-solid fa-image" style="color:purple"/>            
            <span>{{ $t('Upload photo gallery')}} </span>
          </template>
          <CrudGalleryPhotos/>
        </b-tab>   
        <b-tab @click="clickTabAccount($t('My gallery'))">
          <template #title>
            <font-awesome-icon icon="fa-solid fa-image" style="color:purple"/>            
            <span>{{ $t('My gallery')}} </span>
          </template>
          <MyGallery/>          
        </b-tab>         
      </b-tabs>
    </b-card-body>
  </b-card>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import CardProfile from '@/modules/auth/components/CardProfile'
import CardChangePassword from '@/modules/auth/components/CardChangePassword'
import ChangePhotoProfile from '@/modules/auth/components/ChangePhotoProfile'
import CrudGalleryPhotos from '@/modules/auth/components/CrudGalleryPhotos'
import MyGallery from '@/modules/auth/components/MyGallery'
import { utils } from "@/modules/owners/mixins/utils" 

// Skeletons
import SkeletonPhotosGallery from '@/modules/auth/components/skeletons/SkeletonPhotosGallery'

export default {
	mixins: [ utils ],
	components: {
		CardProfile,
		CardChangePassword,
		ChangePhotoProfile,
		CrudGalleryPhotos,
		MyGallery,
		SkeletonPhotosGallery
	},
	data(){
		return {
			isLoadingData: false,                  
		}
	},
	async created(){
		this.isLoadingData = true
		await this.getLangsAndGalleryUser()            
		this.isLoadingData = false
		// addDataUserConnected({page: this.$route.name})		
	},
	computed:{
		...mapState('auth', ['user']),
		...mapState('owners', ['languages']),

  	},
	methods:{
		...mapActions('owners', ['pushActionsService', 'getInitialContentAccountOwner']),		
		...mapActions('auth', ['getPhotosGalleryUser']),
		...mapMutations('auth', ['setGalleryPhotosUser']),
		...mapMutations('owners', ['setLanguages']),
		async getLangsAndGalleryUser(){
			const { languages, gallery } = await this.getInitialContentAccountOwner({ idOwner: this.user.idOwner })
			
			if( this.languages.length === 0 ){
				this.setLanguages(languages)
			}

			if( !this.user.gallery || !this.user.gallery.length){
				this.setGalleryPhotosUser(gallery)         
			}
		},
		async clickTabAccount(infoTab){
			await this.pushData('onClick', 'clickTabOption', 'clickTabOption', this.$route.name, infoTab) // push a back del registr			
		},			
	}
}
// 
</script>
